import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    if (difference <= 0) {

      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <>
      <div className='wispamericaEventTab-tab'>
        <img width={'20px'} src='/assets/wispapalooza/icons/location.svg' />
        <p className='wispamericaEventTab-location'>Omni Oklahoma City</p>
        <img width={'20px'} src='/assets/wispapalooza/icons/calender.svg' />
        <p className='wispamericaEventTab-date1'>March 24 - 27</p>
        <svg className='wispamericaEventTab-svg' width="2" height="50" viewBox="0 0 2 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" x2="1" y2="66" stroke="#616161" strokeOpacity="0.22" strokeWidth="2" />
        </svg>

        <div className='wispamericaEventTab-days' style={{marginLeft:'70px'}}>
          <p className='wispamericaEventTab-number'>{timeLeft.days}</p>
          <p className='wispamericaEventTab-content'>DAYS</p>
        </div>

        <div className='wispamericaEventTab-days'>
          <p className='wispamericaEventTab-number'>{timeLeft.hours}</p>
          <p className='wispamericaEventTab-content'>HOURS</p>
        </div>

        <div className='wispamericaEventTab-days' style={{marginLeft : '30px'}}>
          <p className='wispamericaEventTab-number'>{timeLeft.minutes}</p>
          <p className='wispamericaEventTab-content'>MINUTES</p>
        </div>

        <a href='/book-a-demo' className='wispamericaEventTab-addtoCalendar'>BOOK A DEMO</a>
      </div>
    </>
  );
};

export const WispamericaTop = () => {
  const targetDate = new Date('2025-03-24T00:00:00');

  return (
    <>
      <div className='wispamericaTop'>
        <div className='wispamericaTop-container' style={{background:'url("/assets/events/wispamerica2025/oklahomacity.jpg")', backgroundSize:'cover', backgroundPosition:'top'}}>
          <img width={'220px'} src='/assets/events/wispamerica2025/wispamerica2025.png' />
<div className='wispamericaTop-header-middle'>
          <p className='wispamericaTop-oklahoma' style={{  color: 'rgba(232, 230, 230, 0.2)'}}> OKLAHOMA </p>
          <h2 className='wispamericaTo-meetus'  style={{marginLeft: '70px' }}>MEET US IN <span className='wispamericaTop-person'>PERSON</span></h2>
          </div>
          <p className='wispamericaTop-text'>Head over to booth <span className='wispamericaTop-booth'>#427</span>. Connect with us and see how Paraqum Wi-Di can help fix your network issues.</p>
        </div>

        <div className='wispamericaEventTab'>
          <CountdownTimer targetDate={targetDate} />
        </div>
      </div>
    </>
  );
};
