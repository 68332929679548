import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Helmet } from "react-helmet";
import Card from './Card';
import CardCarousel from './CardCarousel';
import eventData from "./eventData.json";

const Events = () => {

  const cardItems = eventData.map(event => {
    return {
        id: event.id,
        eventName: event.eventName,
        eventYear: event.eventYear,
        bgImageLink: event.bgImageLink,
        status: event.status
    };
});


  return (
    <div className="events">
      <Helmet>
        <title>Paraqum Events</title>
        <meta name="description" content="Paraqum Events" />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>

      <Header />

      <div className="events-header">
        <div className="events-bgoverlay">
          <h4>EVENTS</h4>
          <p>
          Discover Our Latest Innovations and Engagements
            <br />
            at Industry-Leading Events and Conferences
          </p>
        </div>
      </div>
{/* <div className='events-ongoing'>
  <span className='events-title-pre'></span>Latest Events
</div> */}
      <div className="current-event">

        <div className="current-event-left">
          <div className="current-event-image-container">
          <img  className='current-event-wisp-logo' src="/assets/events/wispamerica2025/wispamerica2025.png" alt="wispamerica2025"/>

          <img src="/assets/events/wispamerica2025/oklahomacity.jpg" alt="wispapalooza2024" />
          <div class="current-event-img-overlay"></div>
          </div>

        </div>
        
        <div className="current-event-right">
        <hr className="current-event-hr" />
        <div className='current-event-right-content'>
          <div className="current-event-right-content-upper">
          <p className='current-event-right-content-upper-date'>24-03-2025</p>
          <div className='current-event-right-dot'></div>
          <p className='current-event-right-content-upper-booth'>Booth No - 427</p>
          </div>
          <h1 className="current-event-right-content-title">
          Paraqum @ WISPAMERICA 2025
          </h1>
          <p className='current-event-right-content-des'>
          Join us at booth #427, the Omni Oklahoma City from March 24–27 for an exclusive opportunity to transform your network experience with Paraqum Wi-Di. Witness firsthand how our cutting-edge technology can effectively resolve your network challenges, delivering unparalleled performance and reliability.

Engage with our team of experts to explore innovative solutions tailored to your needs and experience live demonstrations of our state-of-the-art products. Don’t miss this chance to revolutionize your network and elevate your connectivity like never before!</p>
          <a
                href={"/wispamerica-2025"}
        
                rel={"noreferrer"}
             > 
          <button className='current-event-more-info-btn'>
            MORE INFO
          </button>
          </a>

        </div>  
        </div>
      

        
      {/* <Card item={currentEvent}/> */}
      </div>

<div className="events-cardCarousel">
       <CardCarousel  items={cardItems} />
       </div>

      

      <Footer />
    </div>
  );
}

export default Events
