import React, { useState } from 'react';
import Header from '../../components/header';
import Banner from './sections/banner';
import Categories from './sections/categories';
import Infograph from './sections/infograph';
import Features from './sections/features';
import Footer from '../../components/footer';
import Demo from '../../components/demo';
import Notifications from './sections/notifications';
import { Helmet } from "react-helmet";

window.onbeforeunload = function () {
    window.scrollTo(0, 0);
};

const Home = () => {
    const [isTransparent, setIsTransparent] = useState(true);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const goToFeature = (feature) => {
        setSelectedFeature(feature);
    }
    return <div className={"page"}>
        <Helmet>
            <title>Paraqum</title>
            <meta
                name="description"
                content="Paraqum Technologies is a pioneer network solution provider that specializes in network intelligence and control."
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header transparent={isTransparent} />
        {/* <Notifications/> */}
        <Banner setTransparent={(val) => {
            setIsTransparent(val);
        }} />
        <Categories />
        <Infograph goto={(id) => goToFeature(id)} />
        <Features feature={selectedFeature} />
        <Demo />
        <Footer />
    </div>
}

export default Home;