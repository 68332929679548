import React from 'react'

const Card = ({item}) => {
console.log("item :- ",item)
  return (
    <div>

<div className="events-slide-content">
          <div className="events-card-wrapper">
            <div className="events-card">
              <div className="events-image-content">
               4 <span style={{ backgroundImage: `url(${item.bgImageLink})` }} className="events-card-overlay">
                {item?.status && item.status === "latest" && <div className='events-card-latest'>Latest</div>}
                  
                </span>


                  <div className="events-card-image"> 
                    {/* <img src="/assets/events/events-bg.jpg" alt="" className="events-card-img" /> */}
                  </div>

                  <div className="events-card-content">
                    <h2>{item.eventName}&nbsp; - &nbsp;{item.eventYear}</h2>

                    <a
  href={item.status === "latest" ? "wispamerica-2025" : item.status === "disabled" ? '' : `./events/${item.id}`}
  rel="noreferrer"
>
<button
    className={`events-card-button ${item.status === "disabled" ? 'disabled' : ''}`}
    disabled={item.status === "disabled"}
  >
    More Info
  </button>
</a>   
                  </div>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  )
}

export default Card
