import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Timedata from "./Timedata";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const WhereYouNeed = (props) => {
  const defaultProps = {
    center: {
      lat: 6.87030584773,
      lng: 79.8774397373,
    },
    zoom: 16,
  };

  return (
    <>
      <div className="wisp-wondering">
        <div className="wisp-wondering-heading">
        <p className="wisp-wondering-heading-p">
          Wondering where we’ll be at the event? </p>
          <p className="wisp-wondering-subheading">
            {" "}
            Here’s how to find us. We will be at booth #427.
          </p>
        </div>

        <div className="wisp-wondering-floormap">
          <img
            src={"/assets/events/wispamerica2025/wispamerica-2025-floormap.png"}
            alt={"product wispamerica"}
            width={"430px"}
          />
        </div>

        
      </div>

      

      <div className="wisp-wondering-timedata">


      <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: "nowrap" }}>Sunday</span>
                        <br />
                        <span style={{ whiteSpace: "normal" }}>March 23</span>
                      </>
                    }
                    data1={"11:30 AM To 5:00 PM"}
                  />

<Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: "nowrap" }}>Monday</span>
                        <br />
                        <span style={{ whiteSpace: "normal" }}>March 24</span>
                      </>
                    }
                    data1={"7:30 AM To 7.30 PM"}
                    data2={""}
                  /> 


<Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: "nowrap" }}>Tuesday</span>
                        <br />
                        <span style={{ whiteSpace: "normal" }}>March 25</span>
                      </>
                    }
                    data1={"7:00 AM To 5.00 PM"}
                    data2={""}
                  />

<Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: "nowrap" }}>Wednesday</span>
                        <br />
                        <span style={{ whiteSpace: "normal" }}>March 26</span>
                      </>
                    }
                    data1={"7:30 AM To 7:30 PM"}
                  />

        

        </div>



      
    </>
  );
};

export default WhereYouNeed;
